.blogAdmin {
  overflow-y: auto;
  flex-wrap: wrap;
  width: 100%;
}

.postTable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  z-index: 100;
  position: relative;
}

.postTable::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
  z-index: 100;
  position: relative;
}

.postTable::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
  z-index: 100;
  position: relative;
}
.postMiniature {
  width: 40px;
  height: 40px;
}
.paginationNumbers {
  padding-top: 10px;
  flex-basis: 100%;
  align-self: center;
}
.postTable {
  height: 82%;
  overflow-y: auto;
  width: 100%;
  padding: 10px;
}
