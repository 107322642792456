.appsection {
  width: 100%;
}
.apptitle {
  text-align: left;
}
.appicons {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
