.dashboardNews {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.postContainer {
  margin: auto;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(90vh - 30px);
  text-align: justify;
  padding: 10px;
}
.postContainer img {
  max-width: 100%;
  max-width: 100%;
}
.postContainer {
  max-width: 100%;
  max-width: 90%;
}
.postContainer a:hover {
  color: black;
}
.postContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  z-index: 100;
  position: relative;
}

.postContainer::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
  z-index: 100;
  position: relative;
}

.postContainer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
  z-index: 100;
  position: relative;
}
@media (max-width: 850px) {
  .postContainer {
    max-height: 60vh;
  }
}
