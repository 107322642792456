.postImg {
  width: 100%;
  max-width: 600px;
  height: 100%;
  object-fit: cover;
}
.postHolder {
  justify-content: center;
}
.postText {
  text-align: justify;
}
