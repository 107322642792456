.icono-imagen {
  height: 60px;
  float: left;
  margin: 5px;
}
.card-title {
  margin-bottom: 0;
  font-size: 18px !important;
}
.icono {
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.card-text {
  margin-bottom: 0;
  font-size: 15px !important;
}
.icono-texto {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-top: 10px;
  float: left;
  width: 100%;
  text-align: center;
}

.icono-total {
  margin: 5px;
  width: 150px;
  height: 150px;
}

.modal-content-home {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.card-home {
  height: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 6px rgb(0 0 0 / 30%);
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;
}
