.solidbar {
  position: fixed;
  background-color: #eee;
  width: 100vw;
  height: 30px;
  top: 0px;
  left: 0px;
  z-index: 1;
  padding-left: 40px;
  display: flex;
}
.dashcontent {
  margin-top: 30px;
  height: 100vh;
}

#outer-container {
  height: 100vh;
  overflow: hidden;
  background-color: #333;
  position: relative;
}
#page-wrap {
  overflow: auto;
  overflow-x: hidden;
}

.dashcontent {
  /*
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  position: relative;*/
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  align-items: center;
}
.background-img {
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;

  position: absolute;
  top: 0;
  left: 0;
}
.accordion {
  width: 245px;
}

.background-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); /* apply the blur */
  pointer-events: none; /* make the overlay click-through */

  background: -webkit-linear-gradient(
    to left,
    rgba(255, 212, 0, 0.2),
    rgba(237, 28, 36, 0.2)
  );
  background: -o-linear-gradient(
    to left,
    rgba(255, 212, 0, 0.2),
    rgba(237, 28, 36, 0.2)
  );
  background: -moz-linear-gradient(
    to left,
    rgba(255, 212, 0, 0.2),
    rgba(237, 28, 36, 0.2)
  );
  background: linear-gradient(
    to left,
    rgba(255, 212, 0, 0.2),
    rgba(237, 28, 36, 0.2)
  );
}
.background-img {
  background-color: #6c757d;
  height: 100vh;
  width: 100vw;
}
.content-holder {
  border-radius: 10px;
}
.homeIcon{
  padding: 2px;
}
@media (max-width: 850px) {
  .dashboardNews {
    min-height: 400px;
    min-height: 50vh;
  }
  .content-holder {
    padding: 15px;
    position: absolute;
    overflow-y: auto;
    background-color: #fff;
    height: 90vh;
    width: 90vw;
    left: 5%;
    top: 3vh;
  }
  .content-holder::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .content-holder::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .content-holder::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    z-index: 100;
    position: relative;
  }
}
@media (min-width: 850px) {
  .dashboardNews {
    width: 50%;
  }
  .dasboardButton {
    width: 50%;

    overflow-y: auto;
    height: calc(90vh - 30px);
  }
  .content-holder {
    display: flex;
  }
  .content-holder {
    /*
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100vh - 30px);
  overflow-y: scroll;
  width: 100%;

  */
    padding: 15px;
    position: absolute;

    background-color: #fff;
    height: 90vh;
    width: 90vw;
    left: 5%;
    top: 3vh;
  }

  .dasboardButton::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .dasboardButton::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .dasboardButton::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    z-index: 100;
    position: relative;
  }
}
.ayuda-flotante {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 10px;
  right: 10px;
  background-color: #ed1c24;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  padding-top: 5px;
}
