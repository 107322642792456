.offline {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
}
.login-background {
  background-color: #6c757d;
  height: 100vh;
  width: 100vw;
}
.modal-login {
  color: #636363;
  width: 95%;
  max-width: 450px;
}

.modal-login .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}

.modal-login .modal-header {
  border-bottom: none;
  position: relative;
  justify-content: center;
}

.modal-login h4 {
  text-align: center;
  font-size: 26px;
  margin: 20px 0 -10px;
}

.modal-login .form-control:focus {
  border-color: #70c5c0;
}

.modal-login .form-control,
.modal-login .btn {
  min-height: 40px;
  border-radius: 3px;
}

.modal-login .close {
  position: absolute;
  top: -5px;
  right: -5px;
}
