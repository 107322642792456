.flx {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: solid 1px #ccc;
  margin-bottom: 10px;
}
