.passwordForm {
  max-width: 400px;
  padding: 10px;
  border: solid 1px;
  border-radius: 10px;
}
.changepassItem {
  display: flex;
  align-self: center;
  margin: auto;
}
