@font-face {
  font-family: Sudtipos-BlogScriptLight;
  src: local("Sudtipos-BlogScriptLight");
  src: url("./Assets/Fonts/Sudtipos-BlogScriptLight.eot?#iefix")
      format("embedded-opentype"),
    url("./Assets/Fonts/Sudtipos-BlogScriptLight.otf") format("opentype"),
    url("./Assets/Fonts/Sudtipos-BlogScriptLight.svg") format("svg"),
    url("./Assets/Fonts/Sudtipos-BlogScriptLight.ttf") format("truetype"),
    url("./Assets/Fonts/Sudtipos-BlogScriptLight.woff") format("woff"),
    url("./Assets/Fonts/Sudtipos-BlogScriptLight.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: futurastd-book;
  src: url("./Assets/Fonts/FuturaStd-Book.eot");
  src: url("./Assets/Fonts/FuturaStd-Book.eot?#iefix")
      format("embedded-opentype"),
    url("./Assets/Fonts/FuturaStd-Book.otf") format("opentype"),
    url("./Assets/Fonts/FuturaStd-Book.svg") format("svg"),
    url("./Assets/Fonts/FuturaStd-Book.ttf") format("truetype"),
    url("./Assets/Fonts/FuturaStd-Book.woff") format("woff"),
    url("./Assets/Fonts/FuturaStd-Book.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: futurastd-book !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100vh;
}
body {
  height: 100vh;
}
#app {
  height: 100vh;
}
