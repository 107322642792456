body {
  font-family: "Varela Round", sans-serif;
}
.form-input {
  margin-bottom: 15px;
}
.modal-content {
  -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
}
.login-background {
  background-color: #6c757d;
  height: 100vh;
  width: 100vw;
}
.modal-login {
  color: #636363;
  width: 95%;
  max-width: 450px;
}

.modal-login .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}

.modal-login .modal-header {
  border-bottom: none;
  position: relative;
  justify-content: center;
}

.modal-login h4 {
  text-align: center;
  font-size: 26px;
  margin: 20px 0 -10px;
}

.modal-login .form-control:focus {
  border-color: #70c5c0;
}

.modal-login .form-control,
.modal-login .btn {
  min-height: 40px;
  border-radius: 3px;
}

.modal-login .close {
  position: absolute;
  top: -5px;
  right: -5px;
}

.modal-login .modal-footer {
  text-align: center;
  justify-content: center;
  margin: 0 -20px -20px;
  border-radius: 5px;
  font-size: 13px;
}

.modal-login .modal-footer a {
  color: rgb(92, 92, 92);
}

.modal-login .avatar {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: #fff;
  padding: 15px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  display: block;
}

.modal-login .avatar img {
  width: 100%;
}

.modal-login.modal-dialog {
  min-width: 300px;
  max-width: 300px;
  padding: 20px;
  position: absolute;
  height: 400px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -200px 0 0 -150px;
}

.modal-login .btn {
  color: #fff;
  border-radius: 4px;
  background: #ffd400;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
}

.modal-login .btn:hover,
.modal-login .btn:focus {
  background: #ffb400;
  outline: none;
}

.modal-login .btn:active {
  background: #a78b03 !important;
  outline: none;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.login-background::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); /* apply the blur */
  pointer-events: none; /* make the overlay click-through */

  background: -webkit-linear-gradient(
    to left,
    rgba(255, 212, 0, 0.2),
    rgba(237, 28, 36, 0.2)
  );
  background: -o-linear-gradient(
    to left,
    rgba(255, 212, 0, 0.2),
    rgba(237, 28, 36, 0.2)
  );
  background: -moz-linear-gradient(
    to left,
    rgba(255, 212, 0, 0.2),
    rgba(237, 28, 36, 0.2)
  );
  background: linear-gradient(
    to left,
    rgba(255, 212, 0, 0.2),
    rgba(237, 28, 36, 0.2)
  );
}

.modresetheader {
  margin-top: 25px;
  text-align: center;
}
