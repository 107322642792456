header {
  min-width: 305px;
  background-color: #ffd400;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
  border-bottom: 0px solid #ed1c24;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
  color: #ffffff;
  min-width: 305px;
  float: left;
}
.navbar {
  padding: 0px;
}
#closeSesion {
  width: 24px;
  margin-top: -8px;
}
#back {
  display: flex;
  min-height: calc(100vh - 30px - 85px);
  z-index: 1;
  background-color: #ffffff;
}
#container {
  display: flex;
  min-height: calc(100vh - 85px);
  z-index: 1;
}
.blogscript {
  font-family: "BlogScriptLight";
}
.navbar-header {
  float: left;
  margin: 10px;
}
.navbar-collapse {
  float: right;
  margin: 10px;
}
.capHome {
  width: 100%;
}
.button-elem {
}

.button-nav {
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-decoration: none;
  vertical-align: middle;
  border-radius: 5px;
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.tabContent,
.tabContent {
  width: 100%;
  height: 100%;
}
.hw100 {
  height: 100%;
  width: 100%;
}
