.profileTitle {
  flex-basis: 100%;
  height: 50px;
}
.profileContent {
  flex-basis: 100%;
  display: flex;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.profileItem {
  align-content: flex-start;
  width: 100%;
}
.basic-addon1 {
  width: 80px;
}
.profileForm {
  width: 100%;
}
