.editorArea {
  padding-right: 10px;
}
.previewArea {
  padding-right: 10px;
  padding-left: 10px;
}
.previewImg {
  width: 100%;
  max-width: 500px;
  max-height: 500px;
}
.cardNews {
  padding: 10px;
  margin-bottom: 10px;
}
@media (max-width: 850px) {
  .editorArea {
    display: block;
    width: 100%;
  }
  .blogEditHolder {
    padding: 15px;
    
    overflow-y: auto;
    background-color: #fff;
    height: 90vh;
    width: 90vw;
    left: 5%;
    top: 3vh;
  }
  .blogEditHolder::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .blogEditHolder::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .blogEditHolder::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    z-index: 100;
    position: relative;
  }
}
@media (min-width: 850px) {
  .editorArea {
    width: 50%;
    overflow-y: auto;
  }
  .previewArea {
    width: 50%;
    overflow-y: auto;
    height: calc(90vh - 30px);
  }
  .blogEditHolder {
    display: flex;
  }
  .blogEditHolder {
    padding: 15px;
    
    background-color: #fff;
    height: 90vh;
    width: 90vw;
    left: 5%;
    top: 3vh;
  }

  .editorArea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .editorArea::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .editorArea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    z-index: 100;
    position: relative;
  }
  .previewArea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .previewArea::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
    z-index: 100;
    position: relative;
  }

  .previewArea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    z-index: 100;
    position: relative;
  }
}
