a {
  text-decoration: none;
  color: black;
}
.card-header {
  color: black !important;
  background-color: white !important;
}
#blog::after,
#inicio::after {
  background-image: none;
}
.card #blog,
.card #inicio {
  border-radius: 0px;
}

.card {
  border-radius: 0.25rem 0.25rem 0rem 0rem;
}
.dropdown-item {
  white-space: initial;
}
.Logout {
  color: #fff;
  font-size: 45px;
}
.dropdown-item:hover {
  background-color: #eee;
}
.dropdown-item {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 1.25em;
  padding-right: 1.25em;
}
.accordion-body {
  padding-left: 0px;
  padding-right: 0px;
}
.bm-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.bm-menu::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.bm-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.accordion .accsubmenu {
  width: 243px;
  --bs-accordion-active-color: #000;
}
.accsubmenu
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  background-color: rgb(134, 160, 123);
  color: #fff;
}
.accsubmenu .accordion-item .accordion-header .accordion-button {
  background-color: rgb(210, 253, 212);
}
#logoutbuttoncontainer {
  margin-top: 10px;
  width: 245px;
  text-align: center;
}
